<template>
  <div class="space-root h-100">
    <b-spinner 
      v-if="isLoading" 
      class="d-block mx-auto text-primary my-2" 
    />
    <component
      v-else 
      :is="`layout-${layout}`"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import Vue from 'vue';
import { ENABLED_APPS_ACTIONS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import { NOTIFICATIONS_ACTIONS } from '@/store/notifications/notifications-store-constants';
import FaviconPlaceholder from '@/assets/images/placeholders/light/nectios-favicon.png';
import Service from '@/config/service-identifiers';
import { selectLangFromCommunity } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';

import { mapGetters } from 'vuex';

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

export default {
  name: 'SpaceRoot',

  metaInfo: {
    meta: [{
      vmid: 'google-site-verification',
      name: 'google-site-verification',
      content: '???',
    }]
  },

  components: {
    LayoutHorizontal: () => import('@/layouts/horizontal/LayoutHorizontal.vue' /* webpackChunkName: "LayoutHorizontal" */),
    LayoutVertical: () => import('@/layouts/vertical/LayoutVertical.vue' /* webpackChunkName: "LayoutVertical" */),
  },

  data() {
    return {
      isLoading: true,
      user: {},
    };
  },
  computed: {
    ...mapGetters([
      'loggedUser',
      'loggedMember',
      'apps',
      'communityinitialized',
      'mainCollective',
      'currentCollective',
      'collectiveFavicon',
      'integrations',
    ]),
    communitySlug() {
      return this.$route.params.communityId;
    },
    showChatbot() {
      return this.currentCollective?.showChatbot;
    },
    chatbotCustom() {
      return this.currentCollective?.customChatbot;
    },
    documentTitle() {
      return this.currentCollective?.name;
    },
    faviconURL() {
      return getImageResource(this.collectiveFavicon) || FaviconPlaceholder;
    },
    layout() {
      if (this.$route.path.includes('/myself')) {
        return 'vertical';
      }
      if (this.currentCollective?.menu?.layout) {
        return this.currentCollective.menu.layout;
      }
      if (this.$store.state.appConfig?.layout?.type) {
        return this.$store.state.appConfig.layout.type;
      }
      console.log('No layout found');
      return null;
    },
    mainSpace() {
      return this.currentCollective?.parentKey ? this.mainCollective : this.currentCollective;
    }

    // hotjar() {
    //   return this.$store.getters.item('integrations', 'hotjar')
    //     ? this.$store.getters.item('integrations', 'hotjar').customizations
    //     : null;
    // }
  },

  metaInfo() {
    if(this.communitySlug === "terrassaindustria"){
      // <meta name="google-site-verification" content="" />
      return {
        meta: [{
          vmid: 'google-site-verification',
          name: 'google-site-verification',
          content: 'Rwg6wwWtBFZ8SIYUM3uDrzAkLc-W_hgukbI3ZEqGxCU',
        }]
      }
    }
  },

  async created() {
    // console.log('START SpaceRoot.vue')

    this.isLoading = true;

    console.log('Welcome, you are trying to access to a space with slug:', this.communitySlug);
    // console.log('this.loggedUser', this.loggedUser);
    // console.log('this.loggedMember', this.loggedMember);

    if(!this.loggedUser.id && this.$route.name === 'welcome') {
      console.log('You are in welcome and your user is not initialized...');
    }

    //console.log('SpaceRoot.vue (this.mainCollective) ', this.mainCollective)

    // Get Apps
    if (typeof this.apps === 'object' && !this.apps.apps) {
      console.log('Getting space apps...');
      await this.$store.dispatch('getItems', {
        itemType: 'apps',
        page: 1,
        communitySlug: this.communitySlug || null,
      });
    }
    
    // console.log('communityinitialized - start', this.communityinitialized);
    // check if the current community initialized is the same that we have in store
    const currentSpaceIsInitialized = this.communitySlug === this.communityinitialized;
    if (!this.communityinitialized || !currentSpaceIsInitialized) {
      await this.initCommunity(this.communitySlug);
      // console.log('2 SpaceRoot.vue', this.apps)
    }

    // console.log('communityinitialized - end', this.communityinitialized);

    // const memberKey = this.loggedMember.key;
    // const communitySlug = this.communitySlug;
    await startNotifications({
      memberKey: this.loggedMember?.key,
      communitySlug: this.communitySlug,
    });
    await sendMixpanelNotification('member-in-community');

    // this.isLoading = false;

    this.$router.afterEach((to, from) => {
      this.$store.dispatch('addAnalytic', {
        morphType: 'page',
        action: 'enter-page',
        content: to.path,
      });
      if (this.currentCollective) {
        selectLangFromCommunity(this.currentCollective);
      }
    });

    // PWA
    // this.setManifest(this.currentCollective);

    // initialize notifications
    // console.log('Vue.$workbox', Vue.$workbox);
    // if (Vue.$workbox) {
      await this.$store.dispatch(NOTIFICATIONS_ACTIONS.initializeServiceWorker);
    // }

    this.isLoading = false;
    // console.log('FINAL SpaceRoot.vue')
  },

  mounted() {
    //console.log('SpaceRoot mounted...');
    if (!this.$store.state.copernicData.mountedSocket[this.communitySlug]) {
      this.$socket.emit('joinAnalytics', this.communitySlug);
      this.$socket.emit('joinCommunity', this.communitySlug);
      this.$store.state.copernicData.mountedSocket[this.communitySlug] = true;
    }
    if (!this.$store.state.copernicData.mountedSocket.global) {
      this.Notifications();
      this.$store.state.copernicData.mountedSocket.global = true;
    }
  },
  sockets: {
    connect(data) {
      this.$socket.emit('joinAnalytics', this.communitySlug);
      this.$socket.emit('joinCommunity', this.communitySlug);
    },
    notificationCount(params) {
      this.$store.dispatch(NOTIFICATIONS_ACTIONS.unreadCount, params);
    },
    newData(data) {
      if (data.communitySlug === this.communitySlug) {
        this.$store.commit('ADD_ITEM', data);
      }
    },
    updateData(data) {
      if (data.communitySlug === this.communitySlug) {
        this.$store.dispatch('UpdateFromSocket', data);
      }
    }
  },
  methods: {
    Notifications() {
      // do work
      /* this.$socket.emit("receiveNotifications", this.$route.params.communityId);

      //queue more work
      setTimeout(this.Notifications, 15000); */
    },
    async initCommunity(communitySlug) {
      // console.log('SPACE ROOT initCommunity', communitySlug);
      await this.$store.dispatch('initCurrentCommunity', {
        communitySlug: communitySlug,
      });

      selectLangFromCommunity(this.currentCollective);

      // Page title
      document.title = this.translate(this.documentTitle);

      // Other..

      // if (this.currentCollective.isSection
      //   || (this.loggedMember?.id && (this.loggedMember?.status === 3
      //   || this.currentCollective.isStaff === true))
      // ) {
      //   await this.$store.dispatch(ENABLED_APPS_ACTIONS.fetchEnabledApps);
      // }
      await this.$store.dispatch(ENABLED_APPS_ACTIONS.fetchEnabledApps);

      const links = document.querySelectorAll("link[rel~='icon']");
      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        link.href = this.faviconURL;
      }

      const integrations = await this.getIntegrations();
      // console.log('integrations', integrations);

      let tagManager = null;
      let analytics = null;
      if (integrations.length > 0) {
        tagManager = integrations.filter(
          (item) => item.key === 'google-tag-manager',
        )[0]?.customizations?.['Google-Tag-Manager-Key']; // terrassaindustria
        analytics = integrations.filter(
          (item) => item.key === 'google-analytics',
        )[0]?.customizations?.['Google-Analytics-Measurement-ID'];  //conexion-espana y terrassaindustria
      }
      // console.log('Tag manager:', tagManager);
      // console.log('analytics:', analytics);

      // Google Tag Manager: The Main community tag must be in the entire hierarchy
      if (tagManager) {
        console.log('This community has G tag manager with Tag:', tagManager);
        const googleTag = document.createElement('script');
        googleTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-${tagManager}');`;
        document.head.appendChild(googleTag);
        const googleTagBody = document.createElement('script');
        googleTagBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${tagManager}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(googleTagBody);
      }

      // Google Analytics: The Main community tag must be in the entire hierarchy
      if (analytics) {
        console.log('This community has Google Analytics with Tag:', analytics);
        const googleTag = document.createElement('script');
        googleTag.src = `https://www.googletagmanager.com/gtag/js?id=${analytics}`;
        document.head.appendChild(googleTag);
        const googleTagAnalytics = document.createElement('script');
        googleTagAnalytics.innerHTML = `window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${analytics}');`;
        document.head.appendChild(googleTagAnalytics);
      }

      // Head metadata:
      const localSpaceMetadata = this.currentCollective?.headMeta;
      const mainSpaceMetadata = this.mainCollective?.headMeta;
      const metadata = localSpaceMetadata ?? mainSpaceMetadata;
      document.getElementsByName('author').content = metadata?.name_author;
      document.getElementsByName('description').content = metadata?.name_description;
      document.getElementsByName('keywords').content = metadata?.name_keywords;
      document.getElementsByName('twitter:title').content = metadata?.name_twitter_title;
      document.getElementsByName('twitter:description').content = metadata?.name_twitter_description;
      document.getElementById('ogtitle').content = metadata?.property_og_title;
      document.getElementById('ogdescription').content = metadata?.property_og_description;

      // Chatbot
      const chatBot = document.createElement('script');
      // Nectios Support Chat only in Backoffice
      if (this.$route.path.includes('/backoffice')) {
        // chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        // Crisp.user.setEmail(this.$store.getters.loggedUser.email);
      } else {
        const showChatbot = this.mainSpace?.showChatbot ?? null;
        const customChatbot = this.mainSpace?.customChatbot;
        if (showChatbot && customChatbot && !customChatbot.includes('window.')) {
          // Customer's chatbot
          if (showChatbot === 2) { // Crisp
            chatBot.innerText = `window.$crisp=[];window.CRISP_WEBSITE_ID="${customChatbot}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
          } else if (showChatbot === 3) { // Hubspot
            chatBot.type = 'text/javascript';
            chatBot.id = 'hs-script-loader';
            chatBot.async = true;
            chatBot.defer = true;
            chatBot.src = `//js-eu1.hs-scripts.com/${customChatbot}.js`;
          } else if (showChatbot === 4) { // chatbot.com
            chatBot.innerText = `window.__be = window.__be || {};window.__be.id = "${customChatbot}";(function() { var be = document.createElement("script"); be.type = "text\/javascript"; be.async = true; be.src = ("https:" == document.location.protocol ? "https:\/\/" : "http:\/\/") + "cdn.chatbot.com/widget/plugin.js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(be, s);})();`;
          } else if (showChatbot === 5) { // landbot.io
            chatBot.innerText = `window.addEventListener("mouseover", initLandbot, { once: true }); window.addEventListener("touchstart", initLandbot, { once: true }); var myLandbot; function initLandbot() {if (!myLandbot) {var s = document.createElement("script");s.type = "text/javascript";s.async = true;s.addEventListener("load", function() {var myLandbot = new Landbot.Livechat({configUrl: "https://storage.googleapis.com/landbot.online/v3/${customChatbot}/index.json",});});s.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";var x = document.getElementsByTagName("script")[0];x.parentNode.insertBefore(s, x);}}`;
          } else {
            // other
          }
        }
      }
      //console.log('chatBot', chatBot);
      document.body.appendChild(chatBot);
    },
    async getIntegrations() {
      if(this.integrations?.unpaginated?.length > 0){
        return this.integrations.unpaginated;
      }
      return await this.fetchIntegrations();
    },
    async fetchIntegrations() {
      if(!this.communitySlug){
        return [];
      }
      //console.log('Fetching integrations from BE...');
      const integ = await this.$store.$service[Service.BackendClient].get(
          'addons',
          {
            params: {
              type: 'integrations',
              communitySlug: this.mainSpace?.slug,
            },
          },
        ).then((value) => {
          return value.data?.data;
        });
        return integ;
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },

    // setManifest(community) {
    //   console.log('1: ', community);
    //   // console.log('setManifest...')
    //   if (!community) {
    //     // console.log('setManifest: missing "community" param')
    //     community = this.currentCollective;
    //   }

    //   const appURL = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
    //     '{slug}',
    //     community.slug,
    //   ).replace('{subdomain}', community.subdomain || 'app');

    //   console.log('EOEOE: ', community.unsignedLogoURL);

    //   const myDynamicManifest = {
    //     id: community.slug,
    //     name: this.translate(community.name),
    //     short_name: this.translate(community.name),
    //     description:
    //       this.translate(community.description)
    //       || 'Nectios PWA for our platform specialized in events and online communities for our users',
    //     start_url: appURL,
    //     display: 'standalone',
    //     theme_color: '#28ABB9',
    //     background_color: '#28ABB9',
    //     orientation: 'portrait-primary',
    //     icons: [
    //       {
    //         src: getImageResource(community.unsignedLogoURL),
    //         sizes: '48x48 72x72 96x96 128x128 144x144 256x256 257x257',
    //       },
    //     ],
    //   };
    //   console.log('2: ', myDynamicManifest);

    //   const content = encodeURIComponent(JSON.stringify(myDynamicManifest));
    //   const url = `data:application/manifest+json,${content}`;
    //   console.log('2: ', url);

    //   const meta = document.createElement('link');
    //   meta.rel = 'manifest';
    //   meta.href = url;

    //   document
    //     .getElementsByTagName('head')[0]
    //     .insertBefore(meta, document.getElementsByTagName('head')[0].firstChild);

    //   document
    //     .querySelector('#nectios-custom-url-apple')
    //     .setAttribute('content', community.slug);
    //   document
    //     .querySelector('#nectios-custom-name-meta')
    //     .setAttribute('content', community.name);
    //   document
    //     .querySelector('#nectios-custom-name-apple')
    //     .setAttribute('href', community.name);
    //   document
    //     .querySelector('#nectios-custom-icon-apple')
    //     .setAttribute('href', getImageResource(community.unsignedLogoURL));

    //   // console.log('FINAL setManifest...')
    // },
  },
};
</script>
